<template>
  <div class="LiveRooms">
    <ConventionCase :conferenceCode="code"/>
    <div class="liveRntroduce-content">
      <div style="width: 150px">
<!--        <div v-for="(list,index) in pathList" :key="list.id">-->
<!--          <div class="menu">-->
<!--            <span style="margin-left: 5%;" @click="routerLink()">{{list.title}}</span>-->
<!--            &lt;!&ndash; <a v-show="index === 0" class="add" @click="openFunModel('新增')">+</a> &ndash;&gt;-->
<!--          </div>-->
          <a-menu
              style="border-right: none"
              mode="inline"
              :default-selected-keys="['1']"

              :inline-collapsed="collapsed">
            <a-menu-item
                v-for="item in pathList"
                :key="item.id"
                :id="'id'+ item.id"
                ><span>{{item.title}}</span>
            </a-menu-item>
          </a-menu>
<!--        </div>-->
      </div>
      <div style="width: 90%;min-height: 670px;background-color: #fff;border-left: 1px solid #d9d9d9;">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LinkPopup from "@/components/LiveRoomFunModule/LinkPopup";
import {admin_icon} from "@/utils/myIcon";
import ConventionCase from "@/components/meet/ConventionCase";
import pictureDialog from "@/components/Material/pictureDialog";
export default {
name: "DataStatistics",
  components:{
    admin_con: admin_icon,
    LinkPopup,
    ConventionCase,
    pictureDialog
  },
  data(){
    return{
      selectKey:[],
      code:'',
      collapsed:false,
      pathList:[
        {
          id:'1',
          title:'首页数据报告',
          path:'',
          childrens:[],
        },
      ],
    }
  },
  created() {
    this.code = this.$route.query.code;
  },
  methods:{
    routerLink(funCode,funId,row){
      // this.$router.push("/HomepageDataReport")
    },
  }


}
</script>

<style scoped lang="scss">
.LiveRooms{
  width: 100%;
  border: none;
  position:relative;
  //top: -71px;
  border-top: 1px solid #F5F5F5;
  border-left: 1px solid #F5F5F5;
  .liveRntroduce{
    width: 98%;
    min-height: 200px;
    margin: auto;
    display: flex;
    img{
      width: 320px;
      margin: 15px 30px 10px 20px;
    }
    .topRight{
      width: 55%;margin-top: 30px;
      .title{
        font-weight: bold;margin-right: 10px;
      }
      .tag{
        height: 24px;margin-top: 3px;
      }
      .content{
        width: 50%;
        .font{
          margin-top: 10px;
        }
      }
    }
    .topBtn{
      width: 30%;margin-top: 30px;text-align: right;
    }
  }
  .liveRntroduce-content{
    border: 10px solid #EDEFF2;width: 100%;display: flex;
    .menu{
      width: 100%;height: 50px;display: flex;line-height: 50px;
      .add{
      margin-left: 40%;font-size: 30px;z-index: 999;line-height: 40px;
      }
    }
    .icon{
      margin-left: 10px;z-index: 9999;
    }
    .icon2{
      z-index: 9999;
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 300px;
  height: 169px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.bgImg{
  border: #DDDDDD 2px dashed;
  width: 213px;
  min-height: 213px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.surfacePlot{
  border: #DDDDDD 2px dashed;
  width: 213px;
  height: 120px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.uploadImg_img{
  position: absolute;
  top: 0;
  width: 100%;height:100%;
}
.iconStY{
  color:#45a5e6;
  font-size: 20px;
  margin-left: 5px;
  margin-top:3px;float: left;
}
.upImgModel{
  width: 288px;
  min-height: 162px;
  text-align: center;
  background-color: #fcfcfc;
  border: #DDDDDD 2px dashed;
  border-radius: 10px;
  .content{
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-top: 50px;
    color: #929292;
    .addModel{
      font-size: 30px;
    }
  }
}
.icon{
  position: absolute;
  color: red;
  right: 8px;
  top:8px;
  z-index: 99999999999999;
}
</style>
